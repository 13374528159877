import React from 'react';
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import styles from './NavBar.module.scss';

export default function NavBar() {
  const location = useLocation(); // Récupère l'URL actuelle

  return (
    <div className={`${styles.navBar}`}>
      <div className={`${styles.nav}`}>
        <div className={styles.navlinks}>
          <Link 
            to="/" 
            className={`${styles.navLinksItem} ${location.pathname === '/' ? styles.active : ''}`}
          >
            Accueil
          </Link>
          <p className={`${styles.vertical}`}>|</p>
          <Link 
            to="/professorJJB1" 
            className={`${styles.navLinksItem} ${location.pathname === '/professorJJB1' ? styles.active : ''}`}
          >
            Professeurs
          </Link>
          <Link 
            to="/Lessons" 
            className={`${styles.navLinksItem} ${location.pathname === '/Lessons' ? styles.active : ''}`}
          >
            JJB
          </Link>
          <Link 
            to="/renforcement" 
            className={`${styles.navLinksItem} ${location.pathname === '/renforcement' ? styles.active : ''}`}
          >
            Renforcement
          </Link>
          <Link 
            to="/gratuite" 
            className={`${styles.navLinksItem} ${location.pathname === '/gratuite' ? styles.active : ''}`}
          >
            Gratuités
          </Link>
        </div>
      </div>
    </div>
  );
}
