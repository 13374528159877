import { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/auth.context";
import LessonData from '../../data/lesson.json';  // Importer les données des leçons
import styles from './MainNav.module.scss';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

export default function MainNav() {
  const { isLoggedIn, user, logOutUser, authenticateUser } = useContext(AuthContext); 
  const [searchQuery, setSearchQuery] = useState("");  // État pour stocker la recherche
  const [searchResults, setSearchResults] = useState([]);  // État pour stocker les résultats de la recherche
  const [userData, setUserData] = useState(user); // État local pour stocker les informations utilisateur après mise à jour
  const [showDropdown, setShowDropdown] = useState(false); // État pour afficher/cacher le menu déroulant
  const searchResultsRef = useRef(null);  // Ref pour cibler le conteneur de recherche

  // Utiliser useEffect pour surveiller les changements de l'utilisateur
  useEffect(() => {
    setUserData(user); // Mettre à jour l'état local avec l'utilisateur actualisé
  }, [user]); // Le hook se déclenche à chaque modification de l'utilisateur dans le contexte

  const handleCancelAtPeriodEnd = async () => {
    if (!user || !user.subscription || !user.subscription.stripeSubscriptionId) {
      console.error("User or subscription information is missing.");
      alert("Impossible d'annuler l'abonnement, informations manquantes.");
      return;
    }
  
    try {
      const { data } = await axios.post(`${API_URL}/api/cancel-subscription`, {
        userId: user._id,  // Envoie le userId
        subscriptionId: user.subscription.stripeSubscriptionId, // Envoie le subscriptionId
      });
  
      if (data.message) {
        alert('Votre abonnement sera annulé à la prochaine échéance. Vous reçu la confirmation par mail.');
        await authenticateUser();  // Mettre à jour les données utilisateur après l'annulation
      } else {
        alert("Erreur lors de la planification de l'annulation de l'abonnement.");
      }
    } catch (error) {
      console.error("Erreur lors de la tentative d'annulation de l'abonnement:", error);
      alert("Une erreur est survenue lors de l'annulation de votre abonnement.");
    }
  };

  useEffect(() => {
    authenticateUser(); // Vérifie l'authentification lorsque le composant est monté

    // Gestionnaire de clics en dehors
    const handleClickOutside = (event) => {
      if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
        setSearchQuery("");  // Réinitialiser la recherche lorsque l'utilisateur clique en dehors
      }
    };

    // Ajouter l'événement 'click' sur le document
    document.addEventListener('click', handleClickOutside);

    // Nettoyer l'événement lorsque le composant est démonté
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [authenticateUser]);
   
  // Fonction pour gérer la recherche
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  
    // Filtrer les données des leçons en fonction du titre ou de la description
    const filteredLessons = LessonData.filter((lesson) =>
      lesson.title.toLowerCase().includes(query) || 
      lesson.description.toLowerCase().includes(query)
    );
    setSearchResults(filteredLessons);
  };

  // Gestion de l'affichage du menu déroulant
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCancelAtPeriodEndWithConfirmation = async () => {
    const confirmCancel = window.confirm("Êtes-vous sûr de vouloir annuler votre abonnement ?");
  
    if (confirmCancel) {
      await handleCancelAtPeriodEnd();
    } else {
      console.log("L'annulation de l'abonnement a été annulée.");
    }
  };

  return (
    <div className={styles.MainNav}>
      <div className={styles.nav}>
        <Link to="/"> <img src="/drill.jpg" alt="logo" /></Link>
        
        <form className={styles.searchForm}>
          <input
            type="text"
            placeholder="Rechercher un cours"
            className={styles.searchInput}
            value={searchQuery}
            onChange={handleSearch}  // Appeler la fonction de recherche lors de la saisie
          />
          <button type="submit" className={styles.searchButton}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
              <path d="M22.75 11.375C22.75 13.8852 21.9352 16.2039 20.5625 18.0852L27.4859 25.0141C28.1695 25.6977 28.1695 26.8078
              27.4859 27.4914C26.8023 28.175 25.6922 28.175 25.0086 27.4914L18.0852 20.5625C16.2039 21.9406 13.8852 22.75 11.375
              22.75C5.09141 22.75 0 17.6586 0 11.375C0 5.09141 5.09141 0 11.375 0C17.6586 0 22.75 5.09141 22.75 11.375ZM11.375
              19.25C15.7227 19.25 19.25 15.7227 19.25 11.375C19.25 7.02734 15.7227 3.5 11.375 3.5C7.02734 3.5 3.5 7.02734 3.5
              11.375C3.5 15.7227 7.02734 19.25 11.375 19.25Z" fill="white"/>
            </svg>
          </button>
        </form>

        <div className={styles.links}>
          <Link to="/offers">Offres</Link>

          {isLoggedIn && (
            <>
              <div className={styles.profileMenu} onClick={toggleDropdown}>
  <span>{userData && userData.name}</span> 
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={styles.dropdownIcon}>
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
  </svg>
  <div className={`${styles.dropdownMenu} ${showDropdown ? styles.show : ''}`}>
    <ul>
      {userData && userData.subscription && userData.subscription.isActive && !userData.subscription.isCancelledAtPeriodEnd && (
        <li onClick={handleCancelAtPeriodEndWithConfirmation}>Annuler Abonnement</li>
      )}
      <li onClick={logOutUser}>Déconnexion</li>
    </ul>
  </div>
</div>
            </>
          )}
          {!isLoggedIn && (
            <>
              <Link to="/LogIn"> Connexion </Link>
              <Link to="/signup"> M'inscrire </Link>
            </>
          )}
        </div>
      </div>

      {/* Afficher les résultats de la recherche sous la barre de recherche */}
      {searchQuery && (
        <div ref={searchResultsRef} className={styles.searchResultsContainer}>
          {searchResults.length > 0 ? (
            searchResults.map((lesson) => (
              <div key={lesson.id} className={styles.searchResultItem}>
                <Link to={`/Lessons/${lesson.id}`} onClick={() => setSearchQuery("")}>{lesson.title} ({lesson.level}/{lesson.category})</Link>
              </div>
            ))
          ) : (
            <p>Aucun résultat trouvé</p>
          )}
        </div>
      )}
    </div>
  );
}
