import { useState, useContext  } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {  useNavigate } from "react-router-dom";
import styles from "./LogIn.module.scss";
import { AuthContext } from "../../context/auth.context"; 

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';


 

export default function LogIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);
  
  const navigate = useNavigate();
  
  const { storeToken, authenticateUser } = useContext(AuthContext);   //  <== ADD
 
  
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
 
  
  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const requestBody = { email, password };
 
    axios.post(`${API_URL}/auth/login`, requestBody)
      .then((response) => {
        
        
        storeToken(response.data.authToken); 
        authenticateUser();      // <== ADD
      
        navigate('/');
      })
      .catch((error) => {
        const errorDescription = error.response.data.message;
        setErrorMessage(errorDescription);
      })
  };

  return (
    <div className={styles.log}>
    <div className={styles.loginContainer}>
      <h1 className={styles.h1}>Connexion</h1>
      <form className={styles.loginForm} onSubmit={handleLoginSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            placeholder="Entrez votre email"
            className={styles.inputField}
            onChange={handleEmail}
            value={email}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="password">Mot De Passe:</label>
          <input
            type="password"
            id="password"
            name="password"
            required
            placeholder="Entrez votre mot de passe"
            className={styles.inputField}
            onChange={handlePassword}
            value={password}
          />
        </div>
        { errorMessage && <p className="error-message">{errorMessage}</p> }
        <button type="submit" className={styles.loginButton}>
          Se connecter
        </button>
      </form>

      <div className={styles.forgotPasswordLink}>
        <Link to="/forgot-password">Mot de passe oublié ?</Link>
      </div>

      {/* <div className={styles.orSeparator}> */}
        {/* <span>OU</span>
      </div>
      <button className={styles.googleButton} >
        <img src="google.png" alt="Google logo" className={styles.googleLogo} />
        Se connecter avec Google
      </button> */}
    </div>
    </div>
  );
}
